import { AxiosResponse } from 'axios'
import { User } from 'firebase/auth'
import { v4 as uuidv4 } from 'uuid'

import globalConfig from '@/composables/globalConfig'
import { store } from '@/store'
import { Container, PurchaseOrderItem } from '@/types'
import { setError } from '@/utils/helper'
import { instance } from '@/utils/instance'

const { setIsLoading } = globalConfig()

/**
 * Get all containers
 */
export const getContainers = (): Promise<AxiosResponse<{ items: Container[] }>> => instance.get('/v1/containers')

/**
 * Get container by id
 * @param {string} id
 */
export const getContainerById = (id: string): Promise<AxiosResponse<Container>> => instance.get(`/v1/containers/${id}`)

/**
 * Create container
 * @param {string}name
 * @param {PurchaseOrderItem[]} items
 * @param {boolean}isDraft
 * @returns {Promise<Container | null>}
 */
export const createContainer = async (name: string, items: PurchaseOrderItem[], isDraft = false): Promise<Container | null> => {
  // Generate container unique id
  const id = uuidv4()
  // Calculate ready to pick quantity
  items.forEach((item: any) => {
    item.order_quantity = parseInt(String(item.order_quantity), 10)
    if (item.order_quantity < item.rtp_picked) {
      item.rtp_picked = item.rtp_picked - item.order_quantity
    }
  })
  try {
    const payload = {
      id,
      name,
      items,
    }
    const { data } = await instance.post('/v1/containers', payload, {
      params: {
        is_draft: isDraft,
      },
    })
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

/**
 * Update container to approved
 * @param container_id
 */
export const updateContainerApprove = async (container_id: string): Promise<Container | null> => {
  setIsLoading(true)
  try {
    const { data } = await instance.put(
      `/v1/containers/${container_id}/approve`,
      {},
      {
        params: {
          is_approved: true,
        },
      }
    )
    setIsLoading(false)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    setIsLoading(false)
    return null
  }
}

/**
 * Update container to approved
 * @param container_id
 * @param estimateArrival
 */
export const updateContainerEstimateArrival = async (container_id: string, estimateArrival: string): Promise<Container | null> => {
  try {
    const { data } = await instance.put(
      `/v1/containers/${container_id}/estimate-arrival`,
      {},
      {
        params: {
          estimate_arrival: estimateArrival,
        },
      }
    )
    setIsLoading(false)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    setIsLoading(false)
    return null
  }
}

/**
 * Update container to sent
 * @param container_id
 */
export const updateContainerSent = async (container_id: string) => {
  setIsLoading(true)
  try {
    const { data } = await instance.put(
      `/v1/containers/${container_id}/sent`,
      {},
      {
        params: {
          is_sent: true,
        },
      }
    )
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

/**
 * Update container to OTW
 * @param container_id
 */
export const updateContainerOtw = async (container_id: string) => {
  setIsLoading(true)
  try {
    const { data } = await instance.put(
      `/v1/containers/${container_id}/otw`,
      {},
      {
        params: {
          is_sent: true,
        },
      }
    )
    setIsLoading(false)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    setIsLoading(false)
    return null
  }
}
/**
 * Delete Container
 * @param container_id
 */
export const deleteContainer = async (container_id: string) => {
  try {
    const { data } = await instance.delete(`/v1/containers/${container_id}`)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

export const receiveContainer = async (container_id: string) => {
  setIsLoading(true)
  try {
    const { data } = await instance.put(
      `/v1/containers/${container_id}/receive`,
      {},
      {
        params: {
          is_received: true,
        },
      }
    )
    setIsLoading(false)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    setIsLoading(false)
    return null
  }
}

export const updateContainerItemOverride = async (container_id: string, product_id: string, original_quantity: number, override_quantity: number) => {
  try {
    const user: User = store.getters.getUser
    const payload = {
      container_id,
      product_id,
      original_quantity,
      override_quantity,
      uid: user.email,
    }
    const { data } = await instance.put(`/v1/containers/${container_id}/override`, {}, { params: payload })
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

export const updateContainerItemEdit = async (container_id: string, items: PurchaseOrderItem[]) => {
  setIsLoading(true)
  try {
    const { data } = await instance.put(`/v1/containers/${container_id}/edit`, {
      items: items,
    })
    setIsLoading(false)
    return data
  } catch (e) {
    setIsLoading(false)
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}
