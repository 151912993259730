import axios from 'axios'

import { config } from '@/config'
import { getTokenIndexedDB } from '@/helpers/authHelpers'

export const instance = axios.create({
  // baseURL: config.ENVIRONMENT === 'development' ? 'http://localhost:8000/api' : `/api`,
  // Netlify
  baseURL: `https://procurement.highgroup.nz/api`,
})

instance.interceptors.request.use(async (config) => {
  const token = await getTokenIndexedDB()
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token} `
    return config
  }
})
