import { containerConfig, orderViewTable, purchaseOrderConfig, readyToPickConfig } from '@/config/orderConfig'
import { userRoles } from '@/config/userRoles'

export const config = {
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT,
  SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN,
  // ROUTER: process.env.VUE_APP_ENVIRONMENT === 'development' ? '' : '/static',
  // Netlify
  ROUTER: '',
  AG_GRID_LICENSE: process.env.VUE_APP_AG_GRID_LICENSE,
  PO_STATUSES: purchaseOrderConfig.statuses,
  CONTAINER_STATUS: containerConfig.status,
  CONTAINER_STATUSES: containerConfig.statuses,
  RTP_STATUS: readyToPickConfig.status,
  ORDER_VIEW_TABLE: orderViewTable,
  USER_ROLES: userRoles,
  BASE_URI: process.env.VUE_APP_BASE_URI,
  VERSION: process.env.VUE_APP_VERSION,
  STRAPI_APP_ID: process.env.VUE_APP_STRAPI_APP_ID,
  STRAPI_API_KEY: process.env.VUE_APP_STRAPI_API_KEY,
}
