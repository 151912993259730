import { User } from 'firebase/auth'

import globalConfig from '@/composables/globalConfig'
import { store } from '@/store'
import { PurchaseOrder, PurchaseOrderItem } from '@/types'
import { setError } from '@/utils/helper'
import { instance } from '@/utils/instance'

interface PurchaseOrderResponse {
  items: PurchaseOrder[]
  total: number
}

const { setIsLoading } = globalConfig()

/**
 * Create a new purchase order
 * @param {PurchaseOrder} purchaseOrder
 * @param {boolean} isDraft
 * @returns {Promise<PurchaseOrder>}
 */
export const createPurchaseOrder = async (purchaseOrder: any, isDraft = false): Promise<PurchaseOrder> => {
  for (const item of purchaseOrder.items) {
    item.order_quantity = parseInt(item.order_quantity_string, 10)
    item.sold = 0
    item.on_the_way = 0
    item.ready_to_pick = 0
  }
  const { data } = await instance.post('/v1/purchase-orders', purchaseOrder, {
    params: {
      is_draft: isDraft,
    },
  })
  return data
}
/**
 * Update purchase order not draft
 * @param {string} poId
 * @param {PurchaseOrderItem[]} items
 * @param isDraft
 * @return {PurchaseOrder | null} Promise
 */
export const updatePurchaseOrderNotDraft = async (poId: string, items: PurchaseOrderItem[], isDraft: boolean): Promise<PurchaseOrder | null> => {
  try {
    for (const item of items) {
      item.order_quantity = parseInt(item.order_quantity_string, 10)
    }
    const { data } = await instance.post(`/v1/purchase-orders/${poId}`, {
      items: items,
      is_draft: isDraft,
    })
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}
/**
 * Get all purchase orders
 * @returns {Promise<PurchaseOrderResponse>}
 */
export const getPurchaseOrder = async (is_approved?: boolean, is_sent?: boolean): Promise<PurchaseOrderResponse> => {
  setIsLoading(true)
  const { data } = await instance.get('/v1/purchase-orders', {
    params: {
      is_approved: is_approved,
      is_sent: is_sent,
    },
  })
  setIsLoading(false)
  return data
}
/**
 * Get a purchase order by id
 * @param {string} id
 * @returns {Promise<PurchaseOrder | null>}
 */
export const getPurchaseOrderById = async (id: string): Promise<PurchaseOrder | null> => {
  const { data } = await instance.get(`/v1/purchase-orders/${id}`)
  if (data.message) {
    return null
  }
  return data
}
/**
 * Update a purchase order to approve
 * @param {string} id
 * @param {boolean} approve
 * @returns {Promise<PurchaseOrder>}
 */
export const updatePurchaseOrderApprove = async (id: string, approve: boolean): Promise<PurchaseOrder> => {
  setIsLoading(true)
  const { data } = await instance.put(
    `/v1/purchase-orders/${id}/approve`,
    {},
    {
      params: {
        is_approved: approve,
      },
    }
  )
  setIsLoading(false)
  return data
}

/**
 * Update PO to sent
 * @param id
 * @param send
 */
export const updatePurchaseOrderSend = async (id: string, send: boolean) => {
  setIsLoading(true)
  const { data } = await instance.put(
    `/v1/purchase-orders/${id}/sent`,
    {},
    {
      params: {
        is_sent: send,
      },
    }
  )
  setIsLoading(false)
  return data
}

/**
 * Delete PO by po id
 * @param po_id
 */
export const deletePurchaseOrder = async (po_id: string) => {
  try {
    const { data } = await instance.delete(`/v1/purchase-orders/${po_id}`)
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

/**
 * update PO item override
 * @param purchase_order_id
 * @param product_id
 * @param original_quantity
 * @param override_quantity
 */
export const updatePurchaseOrderItemOverride = async (purchase_order_id: string, product_id: string, original_quantity: number, override_quantity: number) => {
  try {
    const user: User = store.getters.getUser
    const payload = {
      purchase_order_id,
      product_id,
      original_quantity,
      override_quantity,
      uid: user.email,
    }
    const { data } = await instance.put(`/v1/purchase-orders/${purchase_order_id}/override`, {}, { params: payload })
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}

/**
 * update PO Production Date
 * @param productionDate
 * @param poId
 */
export const updatePurchaseOrderProductionDate = async (poId: string, productionDate: string) => {
  try {
    const { data } = await instance.put(`/v1/purchase-orders/${poId}/production-date`, {}, { params: { production_date: productionDate } })
    setError('Production date updated', 'success')
    return data
  } catch (e) {
    // @ts-ignore
    setError(e.message, 'danger')
    return null
  }
}
